
export const MonthlyPass = () => {
    return (
        <div className="total-area bg-profile__pass p-4">
            <div className="position-absolute text-center blurr-container">
                <img style={{width: "200px"}} src="/assets/images/Schloss_V1.png" alt="Lock" />
            </div>

            <div className="blurred">
                <div className="head-area d-flex justify-content-between">
                    <div className="left">
                        <h5>Onimos Odyssee</h5>
                        <p className="text-sm">(Monthly Pass)</p>
                    </div>
                    <div className="right">
                        <p className="text-sm text-right">Current Sub Level: <br />
                            <span>3</span>
                        </p>
                    </div>
                </div>
                <ul className="nav nav-tabs mt-5" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link" id="fortnite-tab"
                           data-toggle="tab" href="#fortnite" role="tab"
                           aria-controls="fortnite"
                           aria-selected="true">Free</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="warzone-tab" data-toggle="tab"
                           href="#warzone" role="tab" aria-controls="warzone"
                           aria-selected="false">Tier 1</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="lofl-tab" data-toggle="tab"
                           href="#lofl" role="tab" aria-controls="lofl"
                           aria-selected="false">Tier 2</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="dota-tab" data-toggle="tab"
                           href="#dota" role="tab" aria-controls="dota"
                           aria-selected="false">Tier 3</a>
                    </li>
                </ul>
                <div className="tab-content mt-3" id="myTabContents">
                    <div className="tab-pane fade show active" id="fortnite"
                         role="tabpanel" aria-labelledby="fortnite-tab">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="single-item text-center">
                                    <a href="tournaments-single.html"><img
                                        src="/assets/images/tournaments-slide-1.png" alt="Image" />1</a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-item text-center">
                                    <a href="tournaments-single.html"><img
                                        src="/assets/images/tournaments-slide-1.png" alt="Image" />2</a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-item text-center">
                                    <a href="tournaments-single.html"><img
                                        src="/assets/images/tournaments-slide-1.png" alt="Image" />3</a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-item text-center">
                                    <a href="tournaments-single.html"><img
                                        src="/assets/images/tournaments-slide-1.png" alt="Image" />4</a>
                                </div>
                            </div>
                        </div>

                        <div className="pagination">
                            <a href="#page-1" id="page-1" className="page-btn">1</a>
                            <a href="#page-2" id="page-2" className="page-btn">2</a>
                            <a href="#page-3" id="page-3" className="page-btn">3</a>
                            <a href="#page-4" id="page-4" className="page-btn">4</a>
                            <a href="#page-5" id="page-5" className="page-btn">5</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
