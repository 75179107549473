import ReactDOM from "react-dom/client";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import { Homepage } from "./feature";
import {Upgrader} from "./feature/Upgrader";
import {Profile} from "./feature/Profile";
import {Shop} from "./feature/Shop";
import {ArticleView} from "./feature/Shop/View";
import {Giveaway} from "./feature/Giveaway";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


const Routing = () => {
    return(
        <Router>
            <Routes>
                <Route element={<Homepage />} path="/" />
                <Route element={<Upgrader />} path="/upgrader" />
                <Route element={<Shop />} path="/shop" />
                <Route element={<Giveaway />} path="/giveaway" />
                <Route element={<ArticleView />} path="/article/:id" />
                <Route element={<Profile />} path="/profile/:username" />
            </Routes>
        </Router>
    )
}

root.render(<Routing />);