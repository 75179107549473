import {loginRedirect} from "../../api/AuthenticationService";
import {useEffect, useState} from "react";
import {requestPoints} from "../../api/Profile/ProfileService";

export const Navbar = () => {
    const [isLoggedIn, setLoggedIn] = useState<boolean>(true);
    const [points, setPoints] = useState<any>(null);

    useEffect(() => {
        const params = new URLSearchParams(document.location.search);
        const login = params.get("login");

        if (login) {
            localStorage.setItem('token', login);
            setLoggedIn(true);
        }

        requestPoints().then((response: any) => {
            setPoints(response.data.points);
        }).catch((error: any) => {
            if (error.response.status === 401) {
                localStorage.clear();
                setLoggedIn(false);
            }
        })
    }, [isLoggedIn])

    const RenderPoints = () => {
        return (
            <>
                <p className="pr-2">Seelen: <span className="bg__active">{points?.seelen}</span></p>
                <p>Seelenorbs: <span className="bg__active">{points?.orbs}</span></p>
            </>
        )
    }

    const RenderLoggedIn = () => {
        if (isLoggedIn) {
            return (
                <>
                    <RenderPoints />
                    <a href="#!" onClick={() => {
                        localStorage.clear();
                        setLoggedIn(false);
                    }} className="cmn-btn p-2">Ausloggen</a>
                </>
            )
        }

        return <a href={loginRedirect()} className="cmn-btn">Login</a>
    }

    return(
        <>
            <header id="header-section">
                <div className="overlay">
                    <div className="container">
                        <div className="row d-flex header-area">
                            <div className="logo-section d-flex align-items-center">
                                <a className="site-logo site-title" href="/">
                                    <img src="/assets/images/logo.png"
                                         style={{ height: '45px' }}
                                         alt="site-logo" />
                                    </a>
                            </div>
                            <button className="navbar-toggler ml-auto collapsed" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fas fa-bars"></i>
                            </button>
                            <nav className="navbar navbar-expand-lg p-0">
                                <div className="navbar-collapse collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav main-menu ml-auto">
                                        <li><a className="active" href="/">Boni</a></li>
                                        <li><a className="active" href="/shop">Shop</a></li>
                                        <li><a className="active" href="/game">Game</a></li>
                                        <li><a className="active" href="/upgrader">Upgrader</a></li>
                                        <li><a className="active" href="/bonushunt">Bonushunt</a></li>
                                        <li><a className="active" href="/tournament">Tournament</a></li>
                                    </ul>
                                </div>
                            </nav>
                            <div className="right-area header-action d-flex align-items-center">
                                <RenderLoggedIn />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}