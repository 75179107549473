import {Navbar} from "../Navbar/Navbar";
import {Footer} from "../Footer";

export const Header = ({ children, onLoading }: { children: any, onLoading?: boolean }) => {
    return(
        <>
            {onLoading && (<div className="preloader" id="preloader"></div>)}

            <a href="#" className="scrollToTop"><i className="fas fa-angle-double-up"></i></a>
            <Navbar />

            {children}

            <Footer />
        </>
    )
}