import {Header} from "../../components/Header";
import {Banner} from "./components/Banner";
import {useEffect, useState} from "react";
import {getShopItems} from "../../api/Shop/ShopService";
import {onError} from "../../provider/SweetAlert/SweetAlertProvider";

export const Shop = () => {
    const [articles, setArticles] = useState<any>([]);

    useEffect(() => {
        getShopItems().then((response: any) => {
            setArticles(response.data.items);
        }).catch(() => onError("Es ist ein Fehler aufgetreten"))
    }, []);

    return (
        <Header onLoading={false}>
            <Banner />

            <section className="pb-120">
                <div className="shop container row m-auto">
                    {articles.map((article: any) => {
                        return (
                            <div className="col-12 col-lg-4">
                                <div className="card-1 card-div">
                                    <a href={`/article/${article.id}`}>
                                        <div className="like-icon-div">
                                            <label htmlFor="card-1-like" className="like-icon-div-child">
                                                <input type="checkbox" id="card-1-like" />
                                                <i className="far fa-heart heart-empty"></i>
                                                <i className="fas fa-heart heart-fill"></i>
                                            </label>
                                        </div>

                                        <div className="gow-img-div img-div">
                                            <img
                                                src={article.img}
                                                alt="god-of-war-figurine" />
                                        </div>
                                        <div className="text-container">
                                            <h2 className="item-name">{article.name}</h2>
                                            <div className="pricing-and-cart">
                                                <div className="pricing">
                                                    <p className="current-price">{article.price}€</p>
                                                </div>
                                                <i className="fas fa-shopping-cart"></i>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        )
                    })}


                </div>
            </section>

        </Header>
    )
}