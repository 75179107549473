
export const Statistics = ({ user }: { user: any }) => {
    return (
        <>
            <div className="sidebar-area bg-profile__statistics p-3">
                <div className="title-area">
                    <h5>Statistics</h5>
                </div>
                <ul className="mt-2">
                    <li className="pt-2 pb-2">
                        <span>
                            <img src="/assets/images/my-statistics-icon-1.png" alt="image" className="mr-2" />
                            Seelen
                        </span>
                        <span className="float-right">{user?.coins ?? 0}</span>
                    </li>
                    <li className="pt-2 pb-2">
                        <span><img src="/assets/images/my-statistics-icon-2.png" alt="image" className="mr-2" />Seelenorbs</span>
                        <span className="float-right">{user?.orbs ?? 0}</span>
                    </li>
                    <li className="pt-2 pb-2">
                        <span><img src="/assets/images/my-statistics-icon-3.png" alt="image" className="mr-2" />Total Messages</span>
                        <span className="float-right">{user?.messages ?? 0}</span>
                    </li>
                    <li className="pt-2 pb-2">
                        <span>
                            <img src="/assets/images/my-statistics-icon-4.png" alt="image" className="mr-3" />
                            Watchtime
                        </span>
                        <span className="float-right">{user?.watchtime}h</span>
                    </li>
                </ul>
            </div>
            <div className="sidebar-area bg-profile__earnings p-3 mt-3">
                <div className="title-area">
                    <h5>Earnings</h5>
                    <p className="text-sm">My current earnings</p>
                </div>
                <div className="parents-area">
                    <div className="single-area d-flex align-items-center bg-profile__earnings--symbols mt-2 p-1">
                        <div
                            className="img-area d-flex align-items-center justify-content-center p-2">
                            <img src="/assets/images/earnings-icon-1.png" alt="image" width={35} height="auto" />
                        </div>
                        <div className="right-area m-2">
                            <span><img src="/assets/images/coin-icon.png" alt="image" className="mr-2" width={15} />{user?.allTimePoints ?? 0}</span>
                            <p className="text-sm">Total Souls Earned</p>
                        </div>
                    </div>
                    <div className="single-area d-flex align-items-center bg-profile__earnings--symbols mt-2 p-1">
                        <div
                            className="img-area mid d-flex align-items-center justify-content-center p-2">
                            <img src="/assets/images/earnings-icon-2.png" alt="image" width={35} height="auto" />
                        </div>
                        <div className="right-area">
                            <span><img src="/assets/images/coin-icon.png" alt="image" className="mr-2" width={15} />{user?.allTimeOrbs ?? 0}</span>
                            <p className="text-sm">Total Souls-Orbs Earned</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}