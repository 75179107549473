
import axios from "axios";
import {root} from "../config";

export const requestUpgrader = (percent: number) => {
    return axios({
        url: `${root()}/api/upgrader/play`,
        method: 'post',
        data: {
            percent: percent,
        },
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${localStorage.getItem('token')}`,
        }
    })
}