import React, {useEffect, useState} from "react";
import {getFeaturedOffers, getOffers} from "../../api/offer/OfferService";
import FilterSection from "./FilterSection";

const ActivatedOffer = ({offer, setActivatedOffer}: {offer: any, setActivatedOffer: React.SetStateAction<any>}) => {
    if (!offer) return <></>;

    return (
        <div className="single-item bonus-offer" data-position={offer.position}
             data-percent={offer.bonus} data-max-bonus={offer.maxBonus} data-added-at={offer.createdAt}
             data-wager={offer.wager} data-bonus-type={offer.bonusType}>
            <div className="bonus-offer-front">
                <div className="bonus-offer-back">
                    <div className="row mb-5 mt-4">
                        <div className="col-12 col-lg-2 text-center mb-3">
                            <img className="top-img" style={{ height: 'auto', width: '100%' }} src={offer.img} alt="image" />
                        </div>
                        <div className="col-lg-8 col-12 col-xs-12">
                            <div className="mid-area w-100">
                                {offer.proCons.map((con: any) => {
                                    return <p className="mt-2">{con}</p>;
                                })}
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="text-center">
                                <a href={offer.url} target="_blank" className="cmn-btn p-3">Play now</a>
                                <a className="less-info" href="#!" onClick={(event: any) => {
                                    event.preventDefault();
                                    setActivatedOffer(null);

                                }}>Less Info ➖</a>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12 col-lg-5">
                            <p style={{ color: '#00FADF' }}>Anbieter</p>
                            <div className="single-item text-center" style={{ backgroundColor: '#ffffff1a' }}>
                                {offer.providers.map((provider: any) => <img style={{ width:'125px' }} src={provider.img} />)}
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <p style={{ color: '#00FADF' }}>Zahlungsmethoden</p>
                            <div className="single-item text-center" style={{ backgroundColor: '#ffffff1a' }}>
                                {offer.payments.map((payment: any) => <img src={payment.img} />)}
                            </div>
                        </div>
                        <div className="col-12 col-lg-2">
                            <p style={{ color: '#00FADF' }}>Zahlungsmethoden</p>
                            <div className="single-item text-center" style={{ backgroundColor: '#ffffff1a' }}>
                                LIZENZ
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const OfferView = ({offer}: {offer: any}) => {
    const [activatedOffer, setActivatedOffer] = useState(null);

    if (activatedOffer === offer) {
        return <ActivatedOffer offer={offer} setActivatedOffer={setActivatedOffer} />
    }

    return(
        <div className="single-item bonus-offer border--homepage p-0 mb-4" data-position={offer.position}
             data-percent={offer.bonus} data-max-bonus={offer.maxBonus} data-added-at={offer.createdAt}
             data-wager={offer.wager} data-bonus-type={offer.bonusType}>
            <div className="bonus-offer-front p-0">
                <div className="row mt-4 p-0 m-0">
                    <div className="col-12 col-lg-2 text-center">
                        <img className="text-center" style={{ height: 'auto', width: '100%' }} src={offer.img} alt="image" />
                    </div>
                    <div className="col-lg-8 col-12 col-xs-12 row">
                        <div className="col-12 ml-2">
                            <h4>{offer.name}</h4>
                        </div>
                        <div className="col-12 row ml-2" style={{ borderBottom: '3px solid #370898' }}>
                            <div className="col-6">
                                <div className="single-trophies">
                                    <div className="right">
                                        <h5 className="float-right" style={{ position: 'relative', bottom: '.25rem' }}>ONIMO</h5>
                                        <p className="text-sm float-left"><img src="/assets/images/waitng-icon.png" alt="image" /></p>
                                        <p className="text-sm">PROMOCODE</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 row">
                                {offer.providers.map((provider: any) => {
                                    return (
                                        <div className="col mb-3 text-center ml-auto mr-auto">
                                            <img src={provider.img} className="ml-auto mr-auto text-center" width="100%" height="auto" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="single-trophies" style={{ borderRight: '3px solid #370898', backgroundColor: '#0E063D' }}>
                                <div className="right">
                                    <p className="text-sm">Wager</p>
                                    <h5>{offer.wager}x D+B</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="single-trophies" style={{ borderRight: '3px solid #370898', backgroundColor: '#0E063D' }}>
                                <div className="right">
                                    <p className="text-sm">Max Bet</p>
                                    <h5>{offer.maxBet}€</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="single-trophies" style={{ borderRight: '3px solid #370898', backgroundColor: '#0E063D' }}>
                                <div className="right">
                                    <p className="text-sm">Bonus</p>
                                    <h5>{offer.bonus}%</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="single-trophies" style={{ backgroundColor: '#0E063D' }}>
                                <div className="right">
                                    <p className="text-sm">Max Bonus</p>
                                    <h5>{offer.maxBonus}€</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 mb-4">
                        <div className="text-center">
                            <img src="/assets/images/Banner.png" width="100%" height="auto" style={{ position: 'relative', bottom: '3rem' }} />

                            <a href={offer.url} target="_blank" className="cmn-btn p-3">Play now</a>
                            <a className="more-info" href="#!" onClick={(event: any) => {
                                event.preventDefault();
                                setActivatedOffer(offer)
                            }}>
                                More Info ➕
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const TournamentsSection = () => {
    const [offers, setOffers] = useState([]);
    const [featuredOffers, setFeaturesOffers] = useState([]);

    useEffect(() => {
        getOffers().then((response: any) => {
            setOffers(response.data.casinos);
        }).catch((error: any) => console.log(error))

        getFeaturedOffers().then((response: any) => {
            setFeaturesOffers(response.data.casinos);
        }).catch((error: any) => console.log(error))
    }, []);

    return (
        <section id="tournaments-section" className="all-trophies">
            <div className="overlay pt-120 pb-120">
                <div className="container wow fadeInUp">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8 text-center">
                            <div className="section-header">
                                <h2 className="title">Browse Offers</h2>
                                <p>Find the perfect bonus offer for you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-40 mp-none m-auto">
                        {featuredOffers.map((offer: any) => {
                            return (
                                <div className="col-12 col-lg-4 m-auto p-0">
                                    <div className="single-item text-center filter active" id="highestBonus" style={{ borderRadius: '25px' }}>
                                        <img src={offer.img} width="50%" height="auto" className="mb-2" />

                                        <div className="mid-area w-100 text-center mb-3">
                                            {offer.proCons.map((con: any) => {
                                                return <p className="mt-2 text-sm">{con}</p>;
                                            })}
                                        </div>

                                        <div className="single-trophies col-12 col-lg-10 m-auto mb-4">
                                            <div className="right">
                                                <h5 className="float-right" style={{ position: 'relative', bottom: '.25rem' }}>ONIMO</h5>
                                                <p className="text-sm float-left"><img src="/assets/images/waitng-icon.png" alt="image" /></p>
                                                <p className="text-sm">PROMOCODE</p>
                                            </div>
                                        </div>

                                        <div className="row p-0 mt-3">
                                            <div className="col-lg-4 col-md-6 p-1">
                                                <div className="single-trophies">
                                                    <div className="right">
                                                        <p className="text-sm">{offer.wager}x D+B</p>
                                                        <p className="text-sm">Wager</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 p-1">
                                                <div className="single-trophies">
                                                    <div className="right">
                                                        <p className="text-sm">{offer.maxBet}€</p>
                                                        <p className="text-sm">Max Bet</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 p-1">
                                                <div className="single-trophies">
                                                    <div className="right">
                                                        <p className="text-sm">{offer.bonus}%</p>
                                                        <p className="text-sm">Bonus</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>


                    <FilterSection setOffers={setOffers} />


                    {offers.map((offer: any) => {
                        return <OfferView offer={offer} />
                    })}
                </div>
            </div>
        </section>

    )
}
