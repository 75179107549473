
export const Footer = () => {
    return (
        <footer id="footer-section">
            <div className="footer-mid pt-120">
                <div className="container">
                    <div className="row d-flex">
                        <div
                            className="col-12 d-flex align-items-center justify-content-center">
                            <div className="right-area">
                                <ul className="d-flex">
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitch"></i></a></li>
                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="main-content">
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-lg-12 col-md-6">
                                <div className="left-area text-center">
                                    <p>Copyright © 2023
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}