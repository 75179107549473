
export const Banner = () => {
    return (
        <section id="banner-section">
            <div className="banner-content d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="main-content" style={{ position: 'relative', top: '1rem' }}>
                                <div className="top-area justify-content-center text-center">
                                    <h3 className="m-0">Play Unlimited</h3>
                                    <h1 className="m-0">Tournaments</h1>
                                    <p>Compete in Free and Paid entry Tournaments. Transform your
                                        games to real money eSports</p>
                                    <div className="btn-play d-flex justify-content-center align-items-center">
                                        <a href="registration.html" className="cmn-btn">Get Started</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ani-illu text-center">
                        <img className="left-1" width="25%" height="auto" src="/assets/images/left-banner.png" alt="image" />
                        <img className="right-2" width="25%" height="auto" src="/assets/images/right-banner.png" alt="image" />
                    </div>
                </div>
            </div>
        </section>
    )
}