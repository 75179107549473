type Badge = {
    badgeName: string,
    badgeImage: string
}

export const Badges = ({ user }: { user: any }) => {

    const getDynamicBadges = () => {

        const badge: Badge[] = user.badges;

        return badge.map((item: Badge, index: number) => {
            return (
                <div className="col-lg-3 col-md-6" key={index}>
                <div className="single-trophies d-flex align-items-center">
                    <div className="left d-flex align-items-center justify-content-center">
                        <img src={item.badgeImage} alt="image" />
                    </div>
                    <div className="right">
                        <p>{item.badgeName}</p>
                    </div>
                </div>
                </div>
            )
        })
    }

    return(
        <div className="bg-profile__badge mb-3 p-3 col-12">
            <div className="row">
                {getDynamicBadges()}
            </div>
        </div>
    )
}
