import React, {useEffect, useState} from 'react';
import {getFilters, getOffers, getOffersSearch} from "../../api/offer/OfferService";

const FilterSection = ({setOffers}: { setOffers: React.Dispatch<React.SetStateAction<any>> }) => {
    const [categories, setCategories] = useState<any>([]);
    const [payments, setPayments] = useState<any>([]);

    const [filters, setFilters] = useState({
        search: "",
        category: null,
        payment: null
    })

    useEffect(() => {
        getFilters().then((response: any) => {
            const {categories, payments} = response.data;
            console.log(categories, payments);

            setCategories(categories);
            setPayments(payments);
        })
    }, []);

    useEffect(() => {

        if (filters.category && filters.payment) {
            getOffers(filters.payment, filters.category, filters?.search).then((response: any) => {
                setOffers(response.data.casinos);
            })
        }

        if (filters.search.length >= 3) {
            getOffersSearch(filters.search).then((response: any) => {
                console.log(response.data);
                setOffers(response.data.casinos);
            })
        }

    }, [filters]);

    const searchChange = (event: any) => {
        const {value} = event.target;

        if (value.length >= 3) {
            let stateFilters = {...filters};

            stateFilters.search = value;
            setFilters(stateFilters);
        }
    }

    const onChange = (event: any, category: boolean) => {
        const {value} = event.target;
        let stateFilters = {...filters};

        if (category) {
            stateFilters.category = value;
        } else {
            stateFilters.payment = value;
        }

        setFilters(stateFilters);
    }

    return (
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-4">
                    <input type="text" onChange={(event: any) => searchChange(event)} className="form-control filter" placeholder="Search" />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4">
                    <select className="form-control filter" aria-label="Default select example" onChange={(event: any) => onChange(event, true)}>
                        <option selected>Category</option>
                        {categories.map((category: any) => {
                            return <option value={category.name}>{category.displayName}</option>;
                        })}
                    </select>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4">
                    <select className="form-control filter" aria-label="Default select example" onChange={(event: any) => onChange(event, false)}>
                        <option selected>Payment Method</option>
                        {payments.map((payment: any) => {
                            return <option value={payment.name}>{payment.name}</option>;
                        })}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default FilterSection;
