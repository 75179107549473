import {Header} from "../../components/Header";
import {Banner} from "./components/Banner";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {requestClaimProduct, requestShopItems} from "../../api/Shop/ShopService";
import {errorAlert, onError, successAlert} from "../../provider/SweetAlert/SweetAlertProvider";

const Article = () => {
    const params = useParams();
    const [article, setArticle] = useState<any>(null);

    useEffect(() => {
        requestShopItems(params.id).then((response: any) => {
            setArticle(response.data.item);

            console.log(article);
        }).catch(() => errorAlert());
    }, []);

    const claimProduct = () => {
        requestClaimProduct(article?.id).then((response: any) => {
            const {success} = response.data;

            if (success) {
                successAlert("Du hast das Produkt erfolgreich erworben", "Produkt gekauft")
            } else {
                onError("Du hast nicht genügend Punkte um das Produkt zu kaufen", "Nicht genügend Punkte")
            }

        }).catch((error: any) => onError("Es ist ein Fehler aufgetreten", "Fehler beim kaufen"));
    }

    return (
        <div className="shop card-container col-10">
            <input type="radio" name="colors-btn" id="color-1" checked />
            <input type="radio" name="colors-btn" id="color-2" />
            <input type="radio" name="colors-btn" id="color-3" />
            <input type="radio" name="colors-btn" id="color-4" />
            <div className="shoe-area">
                <div className="floating-div">
                    <div className="shoe-1"><img
                        height="auto"
                        src={article?.img}
                        alt="red-nike" /></div>
                </div>

            </div>
            <div className="text-area">
                <div className="heading-area">
                    <h2>{article?.name}</h2>
                </div>

                <p className="paragraph-area text-dark">{article?.description}</p>
                <div className="price-and-buy-btn">
                    <h2 className="price-1">{article?.price}€</h2>
                    <button disabled={!localStorage.getItem('token')} className="buy-btn" onClick={() => claimProduct()}>BUY NOW</button>
                </div>
            </div>
        </div>
    )
}

export const ArticleView = () => {
    return(
        <Header onLoading={false}>
            <Banner />

            <section className="pb-120 mt-5">
                <div className="container-fluid">
                    <Article />

                </div>
            </section>

        </Header>
    )
}