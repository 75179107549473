import {Header} from "../components/Header";
import {PlayersWeekSection} from "./components/PlayersWeekSection";
import {CounterSection} from "./components/CounterSection";
import {TournamentsSection} from "./components/TournamentsSection";
import {Banner} from "./components/Banner";

export const Homepage = () => {
    return (
        <Header>
            <>
                <Banner />
                <TournamentsSection />
                <CounterSection />
                <PlayersWeekSection />
            </>
        </Header>
    )
}
