import {Header} from "../../components/Header";


export const Giveaway = () => {
    return (
        <Header onLoading={false}>
            <>
                <section id="banner-section" className="inner-banner tournaments">
                    <div className="ani-img">
                        <img className="img-1" src="/assets/images/banner-circle-1.png" alt="icon" />
                        <img className="img-2" src="/assets/images/banner-circle-2.png" alt="icon" />
                        <img className="img-3" src="/assets/images/banner-circle-2.png" alt="icon" />
                    </div>
                    <div className="banner-content d-flex align-items-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <div className="main-content">
                                        <h1>Gewinnspiel</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="headign-info">
                            <div className="top-area">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center">
                                        <img src="/assets/images/character_01.png" alt="image" />
                                    </div>
                                    <div
                                        className="col-lg-4 col-md-4 col-sm-4 d-flex align-items-center justify-content-sm justify-content-center">
                                        <div className="mid-area text-center">
                                            <img src="/assets/images/text-img.png" alt="image" />
                                                <h5>Fortnite Weekly Nano</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 zindex">
                                        <img src="/assets/images/character_02.png" alt="image" />
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-area">
                                <div className="bottom">
                                    <div className="row d-flex justify-content-between pb-3">
                                        <div className="col-lg-8 col-md-8 justify-content-sm-center d-grid">
                                            <h3>Head 2 Head - Weekly - Nano</h3>
                                            <div className="title-bottom d-flex">
                                                <div className="time-area bg">
                                                    <img src="/assets/images/waitng-icon.png" alt="image" />
                                                        <span>Starts in</span>
                                                        <span className="time">10d 2H 18M</span>
                                                </div>
                                                <div className="date-area bg">
                                                    <span className="date">Apr 21, 5:00 AM EDT</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 text-center">
                                            <h2 className="dollar">$739</h2>
                                            <a href="registration.html" className="cmn-btn">Join Now!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section id="tournaments-content">
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="overview" role="tabpanel"
                             aria-labelledby="overview-tab">
                            <div className="container pb-120">
                                <div className="row justify-content-center">
                                    <div className="row col-12 sidebar">
                                        <div className="col-12 col-lg-4 single-side">
                                            <div className="result-single">
                                                <img src="/assets/images/result-img-2.png" alt="images" />
                                                <div
                                                    className="text-area d-flex justify-content-between align-items-center">
                                                    <span>2nd</span>
                                                    <h4>$25.0</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 single-side">
                                            <div className="result-single">
                                                <img src="/assets/images/result-img-2.png" alt="images" />
                                                <div
                                                    className="text-area d-flex justify-content-between align-items-center">
                                                    <span>2nd</span>
                                                    <h4>$25.0</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 single-side">
                                            <div className="result-single">
                                                <img src="/assets/images/result-img-2.png" alt="images" />
                                                <div
                                                    className="text-area d-flex justify-content-between align-items-center">
                                                    <span>2nd</span>
                                                    <h4>$25.0</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-8">
                                        <div className="prizes pb-120">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">Placement</th>
                                                                    <th scope="col">Current Prize</th>
                                                                    <th scope="col">Potential Prize</th>
                                                                    <th scope="col">Team</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <th className="first" scope="row">1st</th>
                                                                    <td>$100.00</td>
                                                                    <td>$100.00</td>
                                                                    <td><i className="fas fa-users"></i>To be decided</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="second" scope="row">2nd</th>
                                                                    <td>$60.00</td>
                                                                    <td>$60.00</td>
                                                                    <td><i className="fas fa-users"></i>To be decided</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="third" scope="row">3rd</th>
                                                                    <td>$40.00</td>
                                                                    <td>$40.00</td>
                                                                    <td><i className="fas fa-users"></i>To be decided</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">4th</th>
                                                                    <td>$25.00</td>
                                                                    <td>$25.00</td>
                                                                    <td><i className="fas fa-users"></i>To be decided</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">5th - 6th</th>
                                                                    <td>$20.00</td>
                                                                    <td>$20.00</td>
                                                                    <td><i className="fas fa-users"></i>To be decided</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">7th - 8th</th>
                                                                    <td>$15.00</td>
                                                                    <td>$15.00</td>
                                                                    <td><i className="fas fa-users"></i>To be decided</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">9th - 11th</th>
                                                                    <td>$10.00</td>
                                                                    <td>$10.00</td>
                                                                    <td><i className="fas fa-users"></i>To be decided</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="sidebar single-side">
                                            <div
                                                className="part-people d-flex align-items-center justify-content-between">
                                                <ul className="d-flex">
                                                    <li><a href="#"><img src="/assets/images/participants-1.png"
                                                                         alt="images" /></a></li>
                                                    <li><a href="#"><img src="/assets/images/participants-2.png"
                                                                         alt="images" /></a></li>
                                                    <li><a href="#"><img src="/assets/images/participants-3.png"
                                                                         alt="images" /></a></li>
                                                    <li><a href="#"><img src="/assets/images/participants-4.png"
                                                                         alt="images" /></a></li>
                                                    <li><a href="#"><img src="/assets/images/participants-5.png"
                                                                         alt="images" /></a></li>
                                                </ul>
                                                <a href="#" className="right-area">12+</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="sidebar">
                                            <div className="single-side">
                                                <div
                                                    className="head-area d-flex justify-content-between align-items-center">
                                                    <h5>Results</h5>
                                                </div>
                                                <div className="result-single">
                                                    <img src="/assets/images/result-img-1.png" alt="images" />
                                                        <div
                                                            className="text-area d-flex justify-content-between align-items-center">
                                                            <span>1st</span>
                                                            <h4>$60.0</h4>
                                                        </div>
                                                </div>
                                                <div className="result-single">
                                                    <img src="/assets/images/result-img-2.png" alt="images" />
                                                        <div
                                                            className="text-area d-flex justify-content-between align-items-center">
                                                            <span>2nd</span>
                                                            <h4>$25.0</h4>
                                                        </div>
                                                </div>
                                                <div className="result-single">
                                                    <img src="/assets/images/result-img-3.png" alt="images" />
                                                        <div
                                                            className="text-area d-flex justify-content-between align-items-center">
                                                            <span>3rd</span>
                                                            <h4>$15.0</h4>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade pb-120" id="bracket" role="tabpanel"
                             aria-labelledby="bracket-tab">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-3">
                                        <div className="bracket-menu">
                                            <div className="single-item text-center">
                                                <h5>Round 1</h5>
                                                <p>8 matches</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3">
                                        <div className="bracket-menu">
                                            <div className="single-item text-center">
                                                <h5>Quarter Finals</h5>
                                                <p>4 matches</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3">
                                        <div className="bracket-menu">
                                            <div className="single-item text-center">
                                                <h5>Semi Finals</h5>
                                                <p>2 matches</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3">
                                        <div className="bracket-menu">
                                            <div className="single-item text-center">
                                                <h5>Finals</h5>
                                                <p>1 matches</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bracket-area">
                                    <div className="row w-cus">
                                        <div className="col-lg-4 col-4 col">
                                            <div className="group-bracket mb-30">
                                                <div className="bracket-single">
                                                    <div className="single-bracket">
                                                        <ul>
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-1.png" alt="image" />
                                                                        <p>Abdul Dowdy</p>
                                                                </div>
                                                                <div className="right">
                                                                    <span>01</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-2.png" alt="image" />
                                                                        <p>Alva Allred</p>
                                                                </div>
                                                                <div className="right green">
                                                                    <span>03</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="single-bracket">
                                                        <ul className="two">
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-3.png" alt="image" />
                                                                        <p>Bill Lackey</p>
                                                                </div>
                                                                <div className="right">
                                                                    <span>01</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-4.png" alt="image" />
                                                                        <p>Sue Plante</p>
                                                                </div>
                                                                <div className="right green">
                                                                    <span>03</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="group-bracket">
                                                <div className="bracket-single">
                                                    <div className="single-bracket">
                                                        <ul className="three">
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-5.png" alt="image" />
                                                                        <p>Abdul Dowdy</p>
                                                                </div>
                                                                <div className="right">
                                                                    <span>01</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-6.png" alt="image" />
                                                                        <p>Mittie Abrams</p>
                                                                </div>
                                                                <div className="right green">
                                                                    <span>03</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="single-bracket">
                                                        <ul className="four">
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-7.png" alt="image" />
                                                                        <p>Adela Peters</p>
                                                                </div>
                                                                <div className="right">
                                                                    <span>01</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-1.png" alt="image" />
                                                                        <p>Owen Boone</p>
                                                                </div>
                                                                <div className="right green">
                                                                    <span>03</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4 col d-grid align-items-center">
                                            <div className="group-bracket">
                                                <div className="bracket-single second">
                                                    <div className="single-bracket mb-155">
                                                        <ul className="five">
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-1.png" alt="image" />
                                                                        <p>Abdul Dowdy</p>
                                                                </div>
                                                                <div className="right">
                                                                    <span>01</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-2.png" alt="image" />
                                                                        <p>Alva Allred</p>
                                                                </div>
                                                                <div className="right green">
                                                                    <span>03</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="single-bracket">
                                                        <ul className="six">
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-1.png" alt="image" />
                                                                        <p>Abdul Dowdy</p>
                                                                </div>
                                                                <div className="right">
                                                                    <span>01</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-2.png" alt="image" />
                                                                        <p>Alva Allred</p>
                                                                </div>
                                                                <div className="right green">
                                                                    <span>03</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4 col d-grid align-items-center">
                                            <div className="group-bracket before-none">
                                                <div className="bracket-single">
                                                    <div className="single-bracket">
                                                        <ul className="seven">
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-1.png" alt="image" />
                                                                        <p>Abdul Dowdy</p>
                                                                </div>
                                                                <div className="right">
                                                                    <span>01</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="left d-flex align-items-center">
                                                                    <img src="images/team-img-2.png" alt="image" />
                                                                        <p>Alva Allred</p>
                                                                </div>
                                                                <div className="right green">
                                                                    <span>03</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="matches" role="tabpanel" aria-labelledby="matches-tab">
                            <div className="matches pb-120">
                                <div className="container overlay-cus">
                                    <div className="row w-cus">
                                        <div className="col-lg-12">
                                            <div className="unscheduled">
                                                <h4>Unscheduled Matches</h4>
                                                <div
                                                    className="single-matches d-flex align-items-center justify-content-between">
                                                    <div className="left">
                                                        <p>14:23</p>
                                                    </div>
                                                    <div
                                                        className="mid d-flex align-items-center justify-content-between">
                                                        <div className="player left-player d-flex align-items-center">
                                                            <p>Angila Hazel</p>
                                                            <span className="tier victory">Tier VII</span>
                                                            <img src="images/participant-1.png" alt="image" />
                                                        </div>
                                                        <h5 className="vs">
                                                            <span>1</span>
                                                            <span>:</span>
                                                            <span>0</span>
                                                        </h5>
                                                        <div className="player right-player d-flex align-items-center">
                                                            <img src="images/participant-2.png" alt="image" />
                                                                <p>Anisa Greiner</p>
                                                                <span className="tier second">Tier VII</span>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <img src="images/right-arrows.png" alt="icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="yesterday">
                                                <h4>Yesterday</h4>
                                                <div
                                                    className="single-matches d-flex align-items-center justify-content-between">
                                                    <div className="left">
                                                        <p>14:23</p>
                                                    </div>
                                                    <div
                                                        className="mid d-flex align-items-center justify-content-between">
                                                        <div className="player left-player d-flex align-items-center">
                                                            <p>Angila Hazel</p>
                                                            <span className="tier victory">Tier VII</span>
                                                            <img src="images/participant-1.png" alt="image" />
                                                        </div>
                                                        <h5 className="vs">
                                                            <span>1</span>
                                                            <span>:</span>
                                                            <span>0</span>
                                                        </h5>
                                                        <div className="player right-player d-flex align-items-center">
                                                            <img src="images/participant-2.png" alt="image" />
                                                                <p>Anisa Greiner</p>
                                                                <span className="tier second">Tier VII</span>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <img src="images/right-arrows.png" alt="icon" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="single-matches d-flex align-items-center justify-content-between">
                                                    <div className="left">
                                                        <p>14:23</p>
                                                    </div>
                                                    <div
                                                        className="mid d-flex align-items-center justify-content-between">
                                                        <div className="player left-player d-flex align-items-center">
                                                            <p>Angila Hazel</p>
                                                            <span className="tier victory">Tier VII</span>
                                                            <img src="images/participant-3.png" alt="image" />
                                                        </div>
                                                        <h5 className="vs">
                                                            <span>1</span>
                                                            <span>:</span>
                                                            <span>0</span>
                                                        </h5>
                                                        <div className="player right-player d-flex align-items-center">
                                                            <img src="images/participant-4.png" alt="image" />
                                                                <p className="eliminated">Anisa Greiner</p>
                                                                <span className="tier second">Tier VII</span>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <img src="images/right-arrows.png" alt="icon" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="single-matches d-flex align-items-center justify-content-between">
                                                    <div className="left">
                                                        <p>14:23</p>
                                                    </div>
                                                    <div
                                                        className="mid d-flex align-items-center justify-content-between">
                                                        <div className="player left-player d-flex align-items-center">
                                                            <p>Angila Hazel</p>
                                                            <span className="tier victory">Tier VII</span>
                                                            <img src="images/participant-5.png" alt="image" />
                                                        </div>
                                                        <h5 className="vs">
                                                            <span>1</span>
                                                            <span>:</span>
                                                            <span>0</span>
                                                        </h5>
                                                        <div className="player right-player d-flex align-items-center">
                                                            <img src="images/participant-6.png" alt="image" />
                                                                <p>Anisa Greiner</p>
                                                                <span className="tier second">Tier VII</span>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <img src="images/right-arrows.png" alt="icon" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="single-matches d-flex align-items-center justify-content-between">
                                                    <div className="left">
                                                        <p>14:23</p>
                                                    </div>
                                                    <div
                                                        className="mid d-flex align-items-center justify-content-between">
                                                        <div className="player left-player d-flex align-items-center">
                                                            <p>Angila Hazel</p>
                                                            <span className="tier victory">Tier VII</span>
                                                            <img src="images/participant-7.png" alt="image" />
                                                        </div>
                                                        <h5 className="vs">
                                                            <span>1</span>
                                                            <span>:</span>
                                                            <span>0</span>
                                                        </h5>
                                                        <div className="player right-player d-flex align-items-center">
                                                            <img src="images/participant-2.png" alt="image" />
                                                                <p className="eliminated">Anisa Greiner</p>
                                                                <span className="tier second">Tier VII</span>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <img src="images/right-arrows.png" alt="icon" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="single-matches d-flex align-items-center justify-content-between">
                                                    <div className="left">
                                                        <p>14:23</p>
                                                    </div>
                                                    <div
                                                        className="mid d-flex align-items-center justify-content-between">
                                                        <div className="player left-player d-flex align-items-center">
                                                            <p>Angila Hazel</p>
                                                            <span className="tier victory">Tier VII</span>
                                                            <img src="images/participant-3.png" alt="image" />
                                                        </div>
                                                        <h5 className="vs">
                                                            <span>1</span>
                                                            <span>:</span>
                                                            <span>0</span>
                                                        </h5>
                                                        <div className="player right-player d-flex align-items-center">
                                                            <img src="images/participant-5.png" alt="image" />
                                                                <p>Anisa Greiner</p>
                                                                <span className="tier second">Tier VII</span>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <img src="images/right-arrows.png" alt="icon" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="single-matches d-flex align-items-center justify-content-between">
                                                    <div className="left">
                                                        <p>14:23</p>
                                                    </div>
                                                    <div
                                                        className="mid d-flex align-items-center justify-content-between">
                                                        <div className="player left-player d-flex align-items-center">
                                                            <p>Angila Hazel</p>
                                                            <span className="tier victory">Tier VII</span>
                                                            <img src="images/participant-2.png" alt="image" />
                                                        </div>
                                                        <h5 className="vs">
                                                            <span>1</span>
                                                            <span>:</span>
                                                            <span>0</span>
                                                        </h5>
                                                        <div className="player right-player d-flex align-items-center">
                                                            <img src="images/participant-8.png" alt="image" />
                                                                <p className="eliminated">Anisa Greiner</p>
                                                                <span className="tier second">Tier VII</span>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <img src="images/right-arrows.png" alt="icon" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="single-matches d-flex align-items-center justify-content-between">
                                                    <div className="left">
                                                        <p>14:23</p>
                                                    </div>
                                                    <div
                                                        className="mid d-flex align-items-center justify-content-between">
                                                        <div className="player left-player d-flex align-items-center">
                                                            <p>Angila Hazel</p>
                                                            <span className="tier victory">Tier VII</span>
                                                            <img src="images/participant-6.png" alt="image" />
                                                        </div>
                                                        <h5 className="vs">
                                                            <span>1</span>
                                                            <span>:</span>
                                                            <span>0</span>
                                                        </h5>
                                                        <div className="player right-player d-flex align-items-center">
                                                            <img src="images/participant-1.png" alt="image" />
                                                                <p className="eliminated">Anisa Greiner</p>
                                                                <span className="tier second">Tier VII</span>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <img src="images/right-arrows.png" alt="icon" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="single-matches d-flex align-items-center justify-content-between">
                                                    <div className="left">
                                                        <p>14:23</p>
                                                    </div>
                                                    <div
                                                        className="mid d-flex align-items-center justify-content-between">
                                                        <div className="player left-player d-flex align-items-center">
                                                            <p>Angila Hazel</p>
                                                            <span className="tier victory">Tier VII</span>
                                                            <img src="images/participant-4.png" alt="image" />
                                                        </div>
                                                        <h5 className="vs">
                                                            <span>1</span>
                                                            <span>:</span>
                                                            <span>0</span>
                                                        </h5>
                                                        <div className="player right-player d-flex align-items-center">
                                                            <img src="images/participant-3.png" alt="image" />
                                                                <p>Anisa Greiner</p>
                                                                <span className="tier second">Tier VII</span>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <img src="images/right-arrows.png" alt="icon" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="participants" role="tabpanel"
                             aria-labelledby="participants-tab">
                            <div className="participants">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="participants-area pb-120">
                                                <h4>Confirmed</h4>
                                                <div className="participants-single">
                                                    <div className="left-area d-flex align-items-center">
                                                        <img src="images/participant-1.png" alt="images" />
                                                            <div className="right-side">
                                                                <h6>Miracle Rosser</h6>
                                                            </div>
                                                    </div>
                                                    <div className="right-area">
                                                        <div className="nice-select"><span
                                                            className="current single-item share">
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                </span>
                                                            <ul className="list">
                                                                <li><a href="#"><i className="fab fa-facebook-f"></i>Share</a>
                                                                </li>
                                                                <li><a href="profile.html"><i
                                                                    className="fas fa-share-alt"></i>View Profile</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="participants-single">
                                                    <div className="left-area d-flex align-items-center">
                                                        <img src="images/participant-2.png" alt="images" />
                                                            <div className="right-side">
                                                                <h6>Miracle Rosser</h6>
                                                            </div>
                                                    </div>
                                                    <div className="right-area">
                                                        <div className="nice-select"><span
                                                            className="current single-item share">
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                </span>
                                                            <ul className="list">
                                                                <li><a href="#"><i className="fab fa-facebook-f"></i>Share</a>
                                                                </li>
                                                                <li><a href="profile.html"><i
                                                                    className="fas fa-share-alt"></i>View Profile</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="participants-single">
                                                    <div className="left-area d-flex align-items-center">
                                                        <img src="images/participant-3.png" alt="images" />
                                                            <div className="right-side">
                                                                <h6>Miracle Rosser</h6>
                                                            </div>
                                                    </div>
                                                    <div className="right-area">
                                                        <div className="nice-select"><span
                                                            className="current single-item share">
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                </span>
                                                            <ul className="list">
                                                                <li><a href="#"><i className="fab fa-facebook-f"></i>Share</a>
                                                                </li>
                                                                <li><a href="profile.html"><i
                                                                    className="fas fa-share-alt"></i>View Profile</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="participants-single">
                                                    <div className="left-area d-flex align-items-center">
                                                        <img src="images/participant-4.png" alt="images" />
                                                            <div className="right-side">
                                                                <h6>Miracle Rosser</h6>
                                                            </div>
                                                    </div>
                                                    <div className="right-area">
                                                        <div className="nice-select"><span
                                                            className="current single-item share">
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                </span>
                                                            <ul className="list">
                                                                <li><a href="#"><i className="fab fa-facebook-f"></i>Share</a>
                                                                </li>
                                                                <li><a href="profile.html"><i
                                                                    className="fas fa-share-alt"></i>View Profile</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="participants-single">
                                                    <div className="left-area d-flex align-items-center">
                                                        <img src="images/participant-5.png" alt="images" />
                                                            <div className="right-side">
                                                                <h6>Miracle Rosser</h6>
                                                            </div>
                                                    </div>
                                                    <div className="right-area">
                                                        <div className="nice-select"><span
                                                            className="current single-item share">
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                </span>
                                                            <ul className="list">
                                                                <li><a href="#"><i className="fab fa-facebook-f"></i>Share</a>
                                                                </li>
                                                                <li><a href="profile.html"><i
                                                                    className="fas fa-share-alt"></i>View Profile</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="participants-single">
                                                    <div className="left-area d-flex align-items-center">
                                                        <img src="images/participant-6.png" alt="images" />
                                                            <div className="right-side">
                                                                <h6>Miracle Rosser</h6>
                                                            </div>
                                                    </div>
                                                    <div className="right-area">
                                                        <div className="nice-select"><span
                                                            className="current single-item share">
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                </span>
                                                            <ul className="list">
                                                                <li><a href="#"><i className="fab fa-facebook-f"></i>Share</a>
                                                                </li>
                                                                <li><a href="profile.html"><i
                                                                    className="fas fa-share-alt"></i>View Profile</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="participants-single">
                                                    <div className="left-area d-flex align-items-center">
                                                        <img src="images/participant-7.png" alt="images" />
                                                            <div className="right-side">
                                                                <h6>Miracle Rosser</h6>
                                                            </div>
                                                    </div>
                                                    <div className="right-area">
                                                        <div className="nice-select"><span
                                                            className="current single-item share">
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                </span>
                                                            <ul className="list">
                                                                <li><a href="#"><i className="fab fa-facebook-f"></i>Share</a>
                                                                </li>
                                                                <li><a href="profile.html"><i
                                                                    className="fas fa-share-alt"></i>View Profile</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="participants-single">
                                                    <div className="left-area d-flex align-items-center">
                                                        <img src="images/participant-8.png" alt="images" />
                                                            <div className="right-side">
                                                                <h6>Miracle Rosser</h6>
                                                            </div>
                                                    </div>
                                                    <div className="right-area">
                                                        <div className="nice-select"><span
                                                            className="current single-item share">
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                </span>
                                                            <ul className="list">
                                                                <li><a href="#"><i className="fab fa-facebook-f"></i>Share</a>
                                                                </li>
                                                                <li><a href="profile.html"><i
                                                                    className="fas fa-share-alt"></i>View Profile</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="participants-single">
                                                    <div className="left-area d-flex align-items-center">
                                                        <img src="images/participant-9.png" alt="images" />
                                                            <div className="right-side">
                                                                <h6>Miracle Rosser</h6>
                                                            </div>
                                                    </div>
                                                    <div className="right-area">
                                                        <div className="nice-select"><span
                                                            className="current single-item share">
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                </span>
                                                            <ul className="list">
                                                                <li><a href="#"><i className="fab fa-facebook-f"></i>Share</a>
                                                                </li>
                                                                <li><a href="profile.html"><i
                                                                    className="fas fa-share-alt"></i>View Profile</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="participants-single">
                                                    <div className="left-area d-flex align-items-center">
                                                        <img src="images/participant-10.png" alt="images" />
                                                            <div className="right-side">
                                                                <h6>Miracle Rosser</h6>
                                                            </div>
                                                    </div>
                                                    <div className="right-area">
                                                        <div className="nice-select"><span
                                                            className="current single-item share">
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                </span>
                                                            <ul className="list">
                                                                <li><a href="#"><i className="fab fa-facebook-f"></i>Share</a>
                                                                </li>
                                                                <li><a href="profile.html"><i
                                                                    className="fas fa-share-alt"></i>View Profile</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="prizes" role="tabpanel" aria-labelledby="prizes-tab">
                            <div className="prizes pb-120">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">Placement</th>
                                                        <th scope="col">Current Prize</th>
                                                        <th scope="col">Potential Prize</th>
                                                        <th scope="col">Team</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <th className="first" scope="row">1st</th>
                                                        <td>$100.00</td>
                                                        <td>$100.00</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="second" scope="row">2nd</th>
                                                        <td>$60.00</td>
                                                        <td>$60.00</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="third" scope="row">3rd</th>
                                                        <td>$40.00</td>
                                                        <td>$40.00</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">4th</th>
                                                        <td>$25.00</td>
                                                        <td>$25.00</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">5th - 6th</th>
                                                        <td>$20.00</td>
                                                        <td>$20.00</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">7th - 8th</th>
                                                        <td>$15.00</td>
                                                        <td>$15.00</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">9th - 11th</th>
                                                        <td>$10.00</td>
                                                        <td>$10.00</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">12th - 15th</th>
                                                        <td>$5.00</td>
                                                        <td>$5.00</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">16th - 19th</th>
                                                        <td>$4.00</td>
                                                        <td>$4.00</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">20th - 26th</th>
                                                        <td>$3.00</td>
                                                        <td>$3.00</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">27th - 34th</th>
                                                        <td>$2.00</td>
                                                        <td>$2.00</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">35th - 44th</th>
                                                        <td>$1.00</td>
                                                        <td>$1.00</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">45th - 75th</th>
                                                        <td>$0.75</td>
                                                        <td>$0.75</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">76th - 115th</th>
                                                        <td>$0.50</td>
                                                        <td>$0.50</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">116th - 180th</th>
                                                        <td>$0.25</td>
                                                        <td>$0.25</td>
                                                        <td><i className="fas fa-users"></i>To be decided</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </>
        </Header>
    )
}