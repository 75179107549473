import axios from "axios";
import {root} from "../config";

export const getOffers = (payment?: any, category?: any, search?: any) => {
    let url = "api/offers/get?limit=100&offset=0";

    if (payment) url += `&payment=${payment}`;
    if (category) url += `&category=${category}`;
    if (search) url += `&search=${search}`;

    return axios({
        url: `${root()}/${url}`,
        method: 'get',
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
    })
}

export const getOffersSearch = (search: string) => {
    return axios({
        url: `${root()}/api/offers/search?name=${search}`,
        method: 'get',
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
    })
}

export const getFeaturedOffers = () => {
        return axios({
            url: `${root()}/api/offers/get?limit=3&offset=0&category=featured`,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
        })
}

export const getFilters = () => {
    return axios({
        url: `${root()}/api/offers/filters/get`,
        method: 'get',
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
    })
}