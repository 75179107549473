
export const PlayersWeekSection = () => {

    return (
        <section id="players-week-section">
            <div className="overlay pt-120 pb-120">
                <div className="container wow fadeInUp">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 mb-30">
                            <div className="section-header text-center">
                                <h2 className="title">Players of the Week</h2>
                                <p>We take a look at the best player of the week awarded
                                    on Monday for the previous Monday to Sunday</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mp-none">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-item text-center">
                                <div className="img-area">
                                    <div className="img-wrapper">
                                        <img src="/assets/images/player-1.png" alt="image" />
                                    </div>
                                </div>
                                <a href="profile.html">
                                    <h5>Barton Griggs</h5>
                                </a>
                                <p className="date">
                                    <span className="text-sm earn">1970 XP Earned</span>
                                    <span className="text-sm">04/05 - 04/12</span>
                                </p>
                                <p className="text-sm credit">
                            <span className="text-sm"><img src="/assets/images/credit-icon.png" alt="image" /> +20
                                credits</span>
                                </p>
                                <a href="profile.html" className="cmn-btn">View Profile</a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-item mid-area text-center">
                                <div className="top-level">
                                    <img src="/assets/images/star.png" alt="image" />
                                </div>
                                <div className="img-area">
                                    <div className="img-wrapper">
                                        <img src="/assets/images/player-2.png" alt="image" />
                                    </div>
                                </div>
                                <a href="profile.html">
                                    <h5>Mervin Trask</h5>
                                </a>
                                <p className="date">
                                    <span className="text-sm earn">1970 XP Earned</span>
                                    <span className="text-sm">04/05 - 04/12</span>
                                </p>
                                <p className="text-sm credit">
                            <span className="text-sm"><img src="/assets/images/credit-icon.png" alt="image" /> +20
                                credits</span>
                                </p>
                                <a href="profile.html" className="cmn-btn">View Profile</a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-item text-center">
                                <div className="img-area">
                                    <div className="img-wrapper">
                                        <img src="/assets/images/player-3.png" alt="image" />
                                    </div>
                                </div>
                                <a href="profile.html">
                                    <h5>Adria Poulin</h5>
                                </a>
                                <p className="date">
                                    <span className="text-sm earn">1970 XP Earned</span>
                                    <span className="text-sm">04/05 - 04/12</span>
                                </p>
                                <p className="text-sm credit">
                            <span className="text-sm"><img src="/assets/images/credit-icon.png" alt="image" /> +20
                                credits</span>
                                </p>
                                <a href="profile.html" className="cmn-btn">View Profile</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}