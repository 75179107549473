import axios from "axios";
import {root} from "../config";

export const getProfile = (username: string) => {
    return axios({
        url: `${root()}/api/profile/${username}/get`,
        method: 'get',
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
    })
}

export const getMeProfile = () => {
    return axios({
        url: `${root()}/api/user/@me`,
        method: 'get',
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': localStorage.getItem('token'),
        }
    })
}

export const requestPoints = () => {
    return axios({
        url: `${root()}/api/points/@me`,
        method: 'get',
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': localStorage.getItem('token'),
        }
    })
}