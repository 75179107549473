import {Header} from "../../components/Header";
import {useState} from "react";
import {ParticleContainer} from "./components/ParticleContainer";
import {ProgressBar} from "./components/ProgressBar";
import {Banner} from "./components/Banner";


export const Upgrader = () => {
    const [isDisabled, setDisabled] = useState<boolean>(false);
    const [isWinner, setWinner] = useState<boolean|null>(null);

    const border = () => {
        if (isWinner === null) return "upgrader__bg--border";

        return (!isWinner) ? 'upgrader__bg--border-red' : 'upgrader__bg--border-green';
    }

    return (
        <Header onLoading={false}>
            <>
                <Banner />

                <section id="featured-products" className="pt-120 pb-120">
                    <div className="container">
                        <div className="row cus-m d-felx justify-content-center">
                            <div className="col-12">
                                <div className={`card upgrader__bg ${border()}`}>
                                    <ParticleContainer disabled={!isDisabled} isWinner={isWinner} />
                                    <ProgressBar disabled={isDisabled} setDisabled={setDisabled} setWin={setWinner} winner={isWinner} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Header>
    )
}