import {useEffect, useState} from "react";
import {requestUpgrader} from "../../../api/upgrader/UpgraderService";
import {requestPoints} from "../../../api/Profile/ProfileService";

export const ProgressBar = ({ disabled, setDisabled, setWin, winner }: {disabled: boolean; setDisabled: React.SetStateAction<any>, setWin: React.SetStateAction<any>, winner: boolean|null}) => {
    const [percent, setPercent] = useState(1);
    const [upgraderPercent, setUpgraderPercent] = useState<any>("00");
    const [percentValue, setPercentValue] = useState(350);
    const [points, setPoints] = useState<any>(null);

    useEffect(() => {
        if (!disabled) {
            requestPoints().then((response: any) => {
                setPoints(response.data.points);
            })
        }
    }, [disabled]);

    useEffect(() => {
        setPercentValue(350 * percent);
    }, [percent]);

    useEffect(() => {
        let bubblyButtons = document.getElementById("bubbly-button");

        if (bubblyButtons) {
            for (let i = 0; i < 10; i++) {
                bubblyButtons.addEventListener('click', animateButton, false);
            }
        }
    }, []);

    const animateButton = () => {
        let bubblyButtons = document.getElementById("bubbly-button");
        bubblyButtons!.classList.remove('animate');
        bubblyButtons!.classList.add('animate');

        setTimeout(() => {
            bubblyButtons!.classList.remove('animate');
        },800);
    };

    const RenderText = () => {
        if (winner === null) return <></>;

        if (winner) {
            return (
                <div className="content">
                    <h2>Orb erfolgreich geschmiedet</h2>
                    <h2>Orb erfolgreich geschmiedet</h2>
                </div>
            )
        }

        return (
            <div className="content content-red">
                <h2>Orb schmieden fehlgeschlagen</h2>
                <h2>Orb schmieden fehlgeschlagen</h2>
            </div>
        )
    }

    const percentColor = (text: boolean = true) => {
        if (winner) return text ? "bar__value-normal" : 'test-bar';

        if (winner === false) {
            return text ? 'bar__value' : 'test-bar-red';
        }

        return text ? "bar__value-blue" : 'test-bar-blue';
    }

    return (
        <>
            <div className="card-header">
                <h4>Upgrader
                    <span className="float-right p-2">
                        SeelenOrbs: {points?.orbs}
                    </span>
                    <span className="float-right p-2">
                        Seelen: {points?.seelen}
                    </span>
                </h4>
            </div>
            <div className="card-body text-center" style={{ zIndex: 99 }}>
                <RenderText />
                <div className="neon-bar">
                    <progress className={percentColor(false)} value={upgraderPercent} max='100'></progress>
                    <span className={percentColor()}>{upgraderPercent}%</span>
                </div>

                <button id="bubbly-button" className="bubbly-button" disabled={disabled} onClick={() => {
                    requestUpgrader(percent).then((response: any) => {

                        const percentValue = parseFloat((Math.random() * 89).toFixed(2));
                        const win = response.data.upgradeSuccess;

                        animateButton();

                        let currentValue = 0;
                        let toValue = (!win) ? Math.round(percentValue) : 100;

                        setDisabled(true);
                        setWin(null);

                        let anim = setInterval(() => {
                            if (currentValue > toValue) {
                                clearInterval(anim);

                                setDisabled(false);
                                (win) ? setWin(true) : setWin(false);
                            } else {
                                setUpgraderPercent(currentValue++ < 100 ? ("0" + currentValue++).slice(-2) : 100)
                            }
                        }, 80);
                    })
                }}>
                    <i className="fa fa-fire"></i>
                </button>

                <label htmlFor="customRange1" className="form-label text-light">{percent}%</label>
                <input type="range"
                       className="form-range"
                       id="customRange1"
                       disabled={disabled}
                       min="1"
                       max="40"
                       value={percent}
                       onChange={(event: any) => setPercent(event.target.value)}
                />

                <p>
                    Schmiede
                    <span style={{ textDecoration: 'underline', paddingLeft: '.3rem', paddingRight: '.3rem' }}>
                        {percentValue}
                    </span>
                    Seelen für die Chance auf einen Seelenorb
                </p>
            </div>
        </>
    )
}