import {requestClaimDaily} from "../../../api/Profile/ClaimDaily";
import {onError, successAlert} from "../../../provider/SweetAlert/SweetAlertProvider";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getMeProfile} from "../../../api/Profile/ProfileService";

export const Banner = ({ user }: { user: any }) => {
    const [showButton, setShopButton] = useState<boolean>(false);
    const [profile, setProfile] = useState<any>(null);
    const params = useParams();

    useEffect(() => {
        getMeProfile().then((response: any) => {
            setProfile(response.data.user);
            setShopButton(response.data.user.username === params!.username);
        }).catch((error: any) => console.error(error));
    }, []);

    const exportDateTime = (datetime: any) => {
        let date = new Date(datetime);

        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()} Uhr`;
    }

    const claimDaily = () => {
        requestClaimDaily().then(() => {
            successAlert("Du hast erfolgreich 1000 Punkte erhalten", "Punkte erhalten");
        }).catch(() => {
            onError("Du hast heute schon Punkte erhalten", "Punkte bereits erhalten")
        });
    }

    return(
        <section id="banner-section" className="inner-banner profile">
            <div className="ani-img">
                <img className="img-1" src="/assets/images/banner-circle-1.png" alt="icon" />
                <img className="img-2" src="/assets/images/banner-circle-2.png" alt="icon" />
                <img className="img-3" src="/assets/images/banner-circle-2.png" alt="icon" />
            </div>
            <div className="banner-content d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="main-content mb-5">
                                <h2>{user?.username}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="heading-area bg-profile__user">
                    <div className="row justify-content-between">
                        <div className="col-md-6">
                            <div className="profile-area d-flex align-items-center">
                                <img src={user?.avatar ?? "/assets/images/profile-logo.png"} alt="Image" width={"20%"} />
                                <div className="ml-3">
                                    <h4>{user?.username}</h4>
                                    <span>Level: 5</span>
                                    <p>{user?.role}</p>
                                </div>
                                <div className="float-right mb-5 ml-2">
                                    <svg fill="#fff" width="2rem" height="2rem" viewBox="0 0 20 20" aria-label="Verifizierter Partner"><path fill-rule="evenodd" d="m10 2 6 2 2 6-2 6-6 2-6-2-2-6 2-6 6-2zM8.889 13.636l5.43-5.429-1.415-1.414-4.015 4.015-2.015-2.015-1.414 1.414 3.429 3.43z" clip-rule="evenodd"></path></svg>
                                </div>

                            </div>
                        </div>
                        {showButton && (
                            <div className="col-md-4 row d-flex justify-content-center justify-content-md-end">
                                <div className="right d-flex align-items-center col-12">
                                    <a href="#!" onClick={() => claimDaily()} className="cmn-btn">Claim daily</a>
                                </div>
                                {!profile?.canClaimDaily && (
                                <div className="col-12">
                                    <p className="ml-2">{exportDateTime(profile?.nextClaimAvaiable)}</p>
                                </div>
                            )}
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </section>
    )
}