import axios from "axios";
import {root} from "../config";

export const requestClaimDaily = () => {
    return axios({
        url: `${root()}/api/user/claim-daily`,
        method: 'post',
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${localStorage.getItem('token')}`
        }
    })
}