import axios from "axios";
import {root} from "../config";

export const getShopItems = () => {
    return axios({
        url: `${root()}/api/shop/get`,
        method: 'get',
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
    })
}

export const requestShopItems = (id: string | undefined) => {
    return axios({
        url: `${root()}/api/shop-item/get?id=${id}`,
        method: 'get',
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
    })
}

export const requestClaimProduct = (id: string | undefined) => {
    return axios({
        url: `${root()}/api/user/claim-product`,
        method: 'post',
        data: {
            id: id
        },
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': localStorage.getItem('token'),
        }
    })
}