import {Header} from "../../components/Header";
import {Banner} from "./components/Banner";
import {Statistics} from "./components/Statistics";
import {Badges} from "./components/Badges";
import {MonthlyPass} from "./components/MonthlyPass";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getMeProfile, getProfile} from "../../api/Profile/ProfileService";
import {onError} from "../../provider/SweetAlert/SweetAlertProvider";

export const Profile = () => {
    const [user, setUser] = useState<any>(null);
    const params = useParams();

    useEffect(() => {
        if (params.username) {
            getProfile(params.username).then((response: any) => {
                setUser(response.data.profile);
            }).catch(() => {
                onError("Das Profil konnte nicht gefunden werden");
            });
        }

        localStorage.setItem('token', '1f550443-31ce-4094-a50e-8cc4b7448435');

        getMeProfile().then((response: any) => {
            console.log(response.data);
        })

    }, []);

    if(user === null) return <></>

    return(
        <Header onLoading={false}>
            <>
                <Banner user={user} />

                <section id="all-trophies">
                    <div className="container">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="overview-tab">
                                <Badges user={user} />
                                <div className="statistics-area">
                                    <div className="row">
                                        <div className="col-lg-9">
                                            <MonthlyPass />

                                            <div className="mt-3 ">
                                                <div>
                                                <div className="text-center position-absolute blurr-container2" style={{zIndex: 100000}}>
                                                    <img style={{width: "200px", position: 'relative', top: '2rem'}} src="/assets/images/Schloss_V1.png" alt="Lock" />
                                                </div>
                                                <div className="total-area bg-profile__quests p-4">
                                                    <div className="head-area d-flex justify-content-between blurred">
                                                        <div className="left">
                                                            <h5>Quests</h5>
                                                        </div>
                                                    </div>
                                                    <div className="tab-content blurred" id="myTabContents">
                                                        <div className="mt-5">
                                                            <div className="neon-bar">
                                                                <div className="float-right text-light" style={{ marginRight: '5rem' }}>
                                                                    500/1000
                                                                </div>
                                                                <h5>Write 1000 Messages</h5>

                                                                <progress className='test-bar' style={{ width: '90%' }} value={50} max='100'></progress>
                                                                <span className='bar__value-normal'>{50}%</span>
                                                            </div>
                                                        </div>

                                                        <div className="mt-5">
                                                            <div className="neon-bar">
                                                                <div className="float-right text-light" style={{ marginRight: '5rem' }}>
                                                                    500/1000
                                                                </div>
                                                                <h5>Write 1000 Messages</h5>

                                                                <progress className='test-bar' style={{ width: '90%' }} value={50} max='100'></progress>
                                                                <span className='bar__value-normal'>{50}%</span>
                                                            </div>
                                                        </div>

                                                        <div className="mt-5">
                                                            <div className="neon-bar">
                                                                <div className="float-right text-light" style={{ marginRight: '5rem' }}>
                                                                    500/1000
                                                                </div>
                                                                <h5>Write 1000 Messages</h5>

                                                                <progress className='test-bar' style={{ width: '90%' }} value={50} max='100'></progress>
                                                                <span className='bar__value-normal'>{50}%</span>
                                                            </div>
                                                        </div>

                                                        <div className="mt-5 text-center">
                                                            <a href="#!" className="btn btn-outline-light">
                                                                Show more Quests
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <Statistics user={user} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Header>
    )
}
